import { userTypes } from "./userTypes"

export const getIsClient = (currentUser) => {
    if (!currentUser) return null;
    return currentUser.attributes.profile.protectedData.userType === userTypes.client;
}

export const getIsStore = (currentUser) => {
    if (!currentUser) return null;
    return currentUser.attributes.profile.protectedData.userType === userTypes.store;
}
export const getIsBrand = (currentUser) => {
    if (!currentUser) return null;
    return currentUser.attributes.profile.protectedData.userType === userTypes.brand;
}

export const getIsLabel = (currentUser) => {
    if (!currentUser) return null;
    return currentUser.attributes.profile.protectedData.userType === userTypes.label;
}

export const getUserType = (currentUser) => {
    if (!currentUser) return null;
    return currentUser.attributes.profile.protectedData.userType;
}

export const getIsApproved = (currentUser) => {
    if (!currentUser) return null;
    return currentUser.attributes.profile.protectedData.isApproved === true;
}